@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');




/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #000000 transparent;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #256D85;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 18px;
    border: 3px solid #256D85;
  }


html{width: 100%;
height: 100%;
}
body{
  background-color: #06283D;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/welcome.webp');
  height: 100%;
  background-size: cover;

}

.section-1{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.form-1{
  width:100%
}



.main-container{
  /* margin: 0 auto; */
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* .login-heading{
  width: 100%;
  text-align: center;
  font-size: 2rem;
  color: white;
  font-family: 'Roboto', sans-serif;
  

} */

.login-input{
  margin-top: 1rem;
    border: none;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  height: 5vh;
  width: 70%;
  font-size: 1rem;
  border-radius: 44px;

}

form{
  width: 100%;
  /* height: 75vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.chat-div{
  position: relative;
  height: 85vh;  
  min-width: 60vw;
  border-radius: 20px;
  /* background: #914040; */
  /* box-shadow: -5px -5px 21px #4b2121, 5px 5px 21px #d75f5f; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.9rem;
  align-items: flex-start;
  padding: 0.5rem;
  background: rgba( 0,0,0, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 3px );
  -webkit-backdrop-filter: blur( 30px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );}

.user-name-h1{
  padding-inline: 0.5rem;
  color: white;
  font-size: 1rem;
}

.user-message-h3{
  padding-inline: 0.5rem;
  color: white;
  font-size: 1rem;
}


.single-chat-div{
  position: relative;
  top: 0.5rem;
  margin-bottom: 0.5rem;
  max-width:50%;
  /* min-width: 40%; */
  border-radius: 7px;
  background-color: #256D85;
/* background: #914040;
box-shadow:  5px 5px 10px #7b3636,
             -5px -5px 10px #a74a4a; */
             
}


.messages-div{
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.align-left{
  align-self: flex-end;
  margin-right: 0.5rem;
}


